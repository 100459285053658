<template>
  <div v-if="btnList">
    <div
      v-if="btnList.length > maxLength"
      class="btn-box"
    >
      <span
        v-for="(el, i) in showBtn"
        :key="i"
      >
        <el-button
          v-show="!el.show || el.show(operateItem)"
          :type="el.type"
          :size="el.size"
          :disabled="el.disabled ? el.disabled(operateItem) : false"
          @click="routeEvent(operateItem, el.code)"
        >
          <span> {{ el.name }}</span>
        </el-button>
      </span>

      <el-dropdown
        trigger="click"
        @command="handleCommand"
      >
        <span class="el-dropdown-link">
          <el-tooltip
            placement="right"
            content="更多操作"
          >
            <em
              class="el-icon-more"
              style="color: #409eff"
            ></em>
          </el-tooltip>
        </span>
        <el-dropdown-menu
          slot="dropdown"
          class="table-opetation-more-dropdown"
        >
          <el-dropdown-item
            v-for="(item, index) in dropData"
            :key="index"
            :command="beforeHandleCommand(operateItem, item.name, item.code)"
            class="link-text"
          >
            <el-button
              :type="item.type"
              :size="item.size"
            >
              {{ item.name }}
            </el-button>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div
      v-else
      style="display: flex"
    >
      <div
        v-for="(item, index) in btnList"
        :key="index"
        class="btn-box"
      >
        <el-button
          :type="item.type"
          :size="item.size"
          v-show="!item.show || item.show(operateItem)"
          :disabled="item.disabled ? item.disabled(operateItem) : false"
          @click="routeEvent(operateItem, item.code)"
        >
          <span> {{ item.name }}</span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
const giiLodash = require('lodash');
/*
    @Description: 操作按钮
    需要传递的参数：
        btnList: 按钮集合
            name: 按钮的名字
            code: 按钮的key
            type: 按钮的类型
        operateItem： 操作的行数据
        maxLength: 默认超过2个按钮显示隐藏下拉，否则平铺 
*/
export default {
  comments: {},
  props: {
    // 按钮集合
    btnList: {
      type: Array,
      default: () => [],
    },
    // 按钮行数据
    operateItem: {
      type: Object,
      default: () => ({}),
    },
    // 默认超过2个按钮显示隐藏下拉，否则平铺
    maxLength: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      dropData: [], // 下拉的按钮组
      showBtn: [], // 不隐藏的按钮
    };
  },
  mounted() {
    if (this.btnList.length > this.maxLength) {
      this.showBtn = this.btnList.slice(0, this.maxLength);
      this.dropData = this.btnList.slice(this.maxLength);
    }
  },
  methods: {
    // 正常按钮点击事件
    routeEvent: giiLodash.debounce(function (data, name) {
      this.$emit('routeEvent', data, name);
    }, 300),
    beforeHandleCommand(data, name, code) {
      return {
        data: data,
        name: name,
        code: code,
      };
    },
    // 下拉菜单点击事件
    handleCommand(command) {
      this.routeEvent(command.data, command.code);
    },
  },
};
</script>
<style lang="scss" scoped>
.link-text .el-button {
  width: 100%;
  text-align: left;
}
.btn-box {
  .el-button {
    min-width: 0px;
    margin-right: 12px;
  }
}
.el-dropdown-link {
  vertical-align: text-top;
}
.el-dropdown {
  vertical-align: middle;
}
</style>
