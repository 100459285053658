<template>
    <div class="flex space-between items-center">
      <inputNumber
        v-bind="$props"
        :value="minValue"
        @change="(val) => change('minValue', val)"
        class="border rounded"
        :class="valid ? '!border-red-500' : ''"
        @blur="onBlur"
      ></inputNumber>
      <span class="px-3">~</span>
      <inputNumber
        v-bind="$props"
        :value="maxValue"
        @change="(val) => change('maxValue', val)"
        class="border rounded"
        :class="valid ? '!border-red-500' : ''"
        @blur="onBlur"
      ></inputNumber>
    </div>
  </template>
  <script>
  import inputNumber from '../input-number/index.vue';
  export default {
    name: 'numberRange',
    components: {
      inputNumber,
    },
    props: {
      minValue: [Number, String],
      maxValue: [Number, String],
      value: {
        type: Number,
        default: 0,
      },
      fixed: Number,
      isValid: Boolean,
    },
    data() {
      return {
        valid: false,
      };
    },
    methods: {
      change(key, value) {
        this.valid = false;
        this.$emit(`update:${key}`, value.trim());
      },
      onBlur() {
        if (this.isValid && this.minValue && this.maxValue) {
          if (this.minValue - this.maxValue > 0) {
            this.valid = true;
          }
        }
      },
    },
  };
  </script>
  